import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers/KC]]
import { KeycloakService } from 'keycloak-angular';
// [[Providers]]
import { ErrorService } from '~services/error.service';
import { DateService } from '~services/date.service';
// ]
@Injectable()
export class CustomerUserService {
  authenticated_user: any;

  constructor(private router: Router, public http: HttpClient, public error: ErrorService, private readonly keycloak: KeycloakService, public dateService: DateService) {}

  /**
   * User
   */
  // ------------------------------------------------------------
  userSubject = new ReplaySubject();
  public async setUser(objectData: Object) {
    this.userSubject.next(JSON.stringify(objectData));
  }

  getUser() {
    return this.userSubject;
  }
  // ------------------------------------------------------------

  /**
   * Token
   */
  // ------------------------------------------------------------
  public async setUserToken(objectData: any) {
    localStorage.setItem('customerUserApiToken', objectData);
  }

  getUserToken() {
    return localStorage.getItem('customerUserApiToken');
  }
  // ------------------------------------------------------------

  /**
   * Merchant
   */
  // ------------------------------------------------------------
  currentMerchant = new ReplaySubject(1);
  public async setCurrentMerchant(objectData: any) {
    this.currentMerchant.next(JSON.stringify(objectData));
  }

  getCurrentMerchant() {
    return this.currentMerchant;
  }
  // ------------------------------------------------------------

  /**
   * Role
   */
  // ------------------------------------------------------------
  roleSubject = new ReplaySubject();
  public async setRole(objectData: Object) {
    this.roleSubject.next(JSON.stringify(objectData));
  }

  getRole() {
    return this.roleSubject;
  }
  // ------------------------------------------------------------

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else if (Object.prototype.toString.call(data[entry]) === '[object Date]') {
          postData[entry] = this.dateService.convertDatePickerTimeToMySQLTime(data[entry]);
        } else {
          postData[entry] = data[entry];
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'panel/customer/user/' + data.id + '?' + querystring, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }
  /**
   *
   * @param data
   */
  getMerchants(data: any) {
    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'user/merchants', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });

      let result = JSON.stringify(request);

      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/customer/user/' + data.id, postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_password(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/customer/user/' + data.id + '/update_password', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_login(data: any) {
    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'panel/customer/user/' + data.id + '/update_login', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update_avatar(data: any) {
    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.post(environment.api_url + 'panel/customer/user/' + data.id + '/avatar', data.upload, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   * Logouts customer user service
   */
  logout() {
    this.keycloak.logout();
  }

  /**
   * Profiles customer user service
   */
  public async profile() {
    let userProfile: any = await this.keycloak.loadUserProfile();
    let userProfileToken: any = await this.keycloak.getToken();

    /* Setting the userProfileToken to the userProfile.api_token. */
    userProfile.api_token = userProfileToken;

    /* Setting the user profile to the userSubject. */
    this.setUser(userProfile);
    this.setUserToken(userProfileToken);
  }
}
