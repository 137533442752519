import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// [[Providers]]
import { ErrorService } from '~services/error.service';
import { DateService } from '~services/date.service';
import { CustomerUserService } from '~project/services/panel/customer/user/user.service';
// ]
@Injectable()
export class CustomerApplicationMerchantService {
  authenticated_user: any = {};

  constructor(private router: Router, public http: HttpClient, public error: ErrorService, public customerUserService: CustomerUserService, public dateService: DateService) {
    this.authenticated_user.api_token = this.customerUserService.getUserToken();
  }

  /**
   * Merchant
   */
  // ------------------------------------------------------------
  merchantSubject = new ReplaySubject();
  public async setMerchant(objectData: Object) {
    this.merchantSubject.next(JSON.stringify(objectData));
  }

  getMerchant() {
    return this.merchantSubject;
  }
  // ------------------------------------------------------------

  /**
   *
   * @param data
   */
  getQuerystring(data: any) {
    let querystring: any = '';
    // tslint:disable-next-line:forin
    for (let entry in data) {
      querystring += entry + '=' + encodeURIComponent(data[entry]) + '&';
    }
    return querystring;
  }

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] === 'object') {
        if (data[entry] == null) {
          postData[entry] = null;
        } else if (Object.prototype.toString.call(data[entry]) === '[object Date]') {
          postData[entry] = this.dateService.convertDatePickerTimeToMySQLTime(data[entry]);
        } else {
          postData[entry] = data[entry];
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param data
   */
  index(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      try {
          let request = this.http.get(environment.api_url + 'user/merchants?' + querystring, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + api_token,
          },
        });
        return request;
      } catch (error) {
        console.log(error);
      }

    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  view(data: any) {
    let querystring = this.getQuerystring(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.get(environment.api_url + 'user/merchants?' + querystring, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  store(data: any) {
    delete data.merchant;

    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.post(environment.api_url + 'user/merchants', postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }

  /**
   *
   * @param data
   */
  update(data: any) {
    delete data.merchant;

    let postData = this.getPost(data);

    if (this.authenticated_user !== null) {
      let api_token = this.authenticated_user.api_token;
      let request = this.http.put(environment.api_url + 'user/merchants/' + data.id, postData, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + api_token,
        },
      });
      return request;
    } else {
      return null;
    }
  }
}
