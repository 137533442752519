<ng-container *ngIf="profile?.id">
  <ng-container *ngIf="merchantId != null">
    <div class="main-container flex flex-col px-4">
      <div class="relative flex justify-between pt-4">
        <div class="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
          <div class="flex-shrink-0 flex items-center">
            <a [routerLink]="['/guard']">
              <img class="block" src="/assets/images/logo.svg" alt="Trivea" />
            </a>
          </div>
        </div>
        <div class="flex items-center justify-end col-span-4 mx-4 space-x-3 text-black">
          <a [routerLink]="['/guard/customer/order/active']" class="text-sm font-medium underline underline-offset-4"> {{profile.firstName}} </a>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer stroke-gray-600 hover:stroke-gray-900">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
          <!--
        <div>
          <ng-container *ngIf="merchants?.results.length == 0">
            <div>
              <select class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option *ngFor="let merchant of merchants.results;" value="PP_PJ">{{merchant.name}}</option>
              </select>
            </div>
          </ng-container>
        </div>
        --></div>
      </div>
    </div>
  </ng-container>
</ng-container>
