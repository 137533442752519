import { environment } from 'src/environments/environment';
import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, Input, ComponentFactoryResolver } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
// [[Providers]]
import { CustomerUserService } from '~project/services/panel/customer/user/user.service';
import { CustomerApplicationMerchantService } from '~project/services/panel/application/merchant/merchant.service';
// ]
// [[Interfaces do Componente]]
// ]
@Component({
  selector: 'app-guard-header',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
})
export class GuardHeaderComponent {
  /**
   * Base de uso em Component
   */
  public environment = environment;
  messageProcess = false;
  messageIsError: any;
  profile: any;
  merchantId: any;

  favicon: HTMLLinkElement = document.querySelector('#app_icon');

  actualUrl: any = this.router.url;
  openMenuMobile: any = false;
  openSubMenuMobile: any = false;

  merchants: any;

  constructor(
    /**
     * Serviços da Aplicação
     */
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    /**
     * Base de uso em Component
     */
    private userService: CustomerUserService,
    private customerApplicationMerchantService: CustomerApplicationMerchantService
    /**
     * Services
     */
  ) {}

  /**
   * on init
   */
  ngOnInit() {
    this.ipBuildAppDependencies();
  }

  /**
   * build app dependencies
   */
  ipBuildAppDependencies() {
    /**
     * Base
     *
     * Request da base de uso.
     */
    // Current Profile
    this.userService.getUser().subscribe((Profile: any) => {
      if (Profile != null) {
        if (JSON.parse(Profile) != null) {
          this.profile = JSON.parse(Profile);
        }
      }
    });
    // Current Merchant
    this.userService.getCurrentMerchant().subscribe((MerchantId: any) => {
      if (MerchantId != null) {
        if (JSON.parse(MerchantId) != null) {
          this.merchantId = JSON.parse(MerchantId);
        }
      }
    });
    this.customerApplicationMerchantServiceIndex();
  }

  /**
   * after view init
   */
  ngAfterViewInit() {}

  /**
   * Collectives customer user service logout
   */
  collectiveCustomerUserServiceLogout() {
    localStorage.clear();
    this.userService.logout();
  }

  /**
   * Customers application merchant service index
   */
  customerApplicationMerchantServiceIndex() {
    var _ = this.customerApplicationMerchantService.index({
      limit: 100,
      offset: 0,
    });
    if (_ !== null) {
      _.subscribe(
        (response: any) => {
          // console.log(response);
          this.merchants = response;

          //@todo abstrair essa logica para service específico
          if (this.merchants.results.length >= 1) {
            //console.log('Loja Configurada: ' + this.merchants.results[0].id);
            // Current Merchant
            this.userService.setCurrentMerchant(this.merchants.results[0].id);
          }

          if (this.merchants.results.length == 0) {
            // Current Merchant
            this.userService.setCurrentMerchant(null);
          }
        },
        (error) => {
          this.messageProcess = false;
          var errorThrow = this.customerApplicationMerchantService.error.throw(error);
          if (errorThrow.hasOwnProperty('errors')) {
            errorThrow.errors.forEach((erro) => {
              // return this.modalAlert.open("", erro);
            });
          }
        }
      );
    }
  }
}
