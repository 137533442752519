import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// [[Providers/KC]]
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
// [[Providers]]
import { CustomerUserService } from '~project/services/panel/customer/user/user.service';
// ]

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  constructor(protected readonly router: Router, protected readonly keycloak: KeycloakService, private customerUserService: CustomerUserService) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      localStorage.removeItem('customerUserApiToken');

      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    } else {
      await this.customerUserService.profile();
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
