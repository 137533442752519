// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

var PublicSuffixList = require("psl");
var domainObject = PublicSuffixList.parse(document.domain);
// {
//     "input": "www.iporto.io",
//     "tld": "io",
//     "sld": "iporto",
//     "domain": "iporto.io",
//     "subdomain": "www",
//     "listed": true
// }

export const environment = {
  production: true,
  app_name: "Trivea [PROD]",

  // Dev
  api_url: "https://api.trivea.com.br/",

  s3_image_url: "https://trivea-images.s3.amazonaws.com",

  page: {
    url: document.URL,
    domain: document.domain,
    expression: document.location.pathname,
  },

  keycloak: {
    config: {
      // trivea:trivea
      url: "https://sso.trivea.com.br", // 10.10.0.8 	sso.trivea.com.br
      realm: "trivea",
      clientId: "frontend-service",
    },
  },
};
