import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// Keycloak
import { APP_INITIALIZER } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory';

// Portuguese
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

// PrimeNg
import { MessageService, ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule, LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { NgModule, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT, Inject } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from 'src/project/modules/auth/guard';
import { CustomerUserService } from '~project/services/panel/customer/user/user.service';
import { CustomerApplicationMerchantService } from '~project/services/panel/application/merchant/merchant.service';
import { ErrorService } from 'src/project/services/error.service';
import { DateService } from 'src/project/services/date.service';
import { CacheService } from 'src/project/services/cache.service';

import { enableProdMode } from '@angular/core';
enableProdMode();

// [[Layout]]
import {
  // [[Modulo de Guard]]
  LayoutGuardComponent,
  GuardHeaderComponent,
  // []
  // [[Modulo de Www]]
  LayoutWwwComponent,
  // []
} from '../project/layout';
// []

@NgModule({
  declarations: [
    AppComponent,
    // [[Layout Guard]]
    LayoutGuardComponent,
    GuardHeaderComponent,
    // []
    // [[Modulo de Www]]
    LayoutWwwComponent,
    // []
  ],
  imports: [
    //
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    AppRoutingModule,
    KeycloakAngularModule,
    FormsModule,
    ReactiveFormsModule,
    // PrimeNg
    TooltipModule,
    ToastModule,
    // ------------------
  ],
  providers: [
    AuthGuard,
    CustomerUserService,
    CustomerApplicationMerchantService,
    ErrorService,
    // PrimeNg
    MessageService,
    // ------------------
    DateService,
    CacheService,
    { provide: APP_BASE_HREF, useValue: '/' },
    // Portuguese
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
    {
      provide: LOADING_BAR_CONFIG,
      useValue: { latencyThreshold: 100 },
    },
    // Keycloak
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Inject(DOCUMENT) private document: Document) {}
}
