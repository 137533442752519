import { environment } from "../../environments/environment";
import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.config.url,
        realm: environment.keycloak.config.realm,
        clientId: "frontend-service",
      },
      initOptions: {
        onLoad: "login-required",
        // checkLoginIframe: false,
        // onLoad: "check-sso",
        // silentCheckSsoRedirectUri: window.location.origin + "/assets/silent-check-sso.html",
      },
      loadUserProfileAtStartUp: true,
      enableBearerInterceptor: false,
      bearerExcludedUrls: ["/assets", "/clients/public", "https://viacep.com.br"],
    });
}
