import { environment } from "src/environments/environment";
import { Injectable, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// [[Providers]]
import { DateService } from "~services/date.service";
// ]
@Injectable()
export class ErrorService {
  constructor(public http: HttpClient, public dateService: DateService) {}

  /**
   *
   * @param data
   */
  getPost(data: any) {
    let postData: any = {};
    for (let entry in data) {
      if (typeof data[entry] == "object") {
        if (data[entry] == null) {
          postData[entry] = null;
        } else if (Object.prototype.toString.call(data[entry]) === "[object Date]") {
          postData[entry] = this.dateService.convertDatePickerTimeToMySQLTime(data[entry]);
        } else {
          postData[entry] = data[entry];
        }
      } else {
        postData[entry] = data[entry];
      }
    }
    return postData;
  }

  /**
   *
   * @param err
   */
  throw(originalHttpError: any) {
    let errors = [];

    if (originalHttpError.error !== null && originalHttpError.error?.codeError !== null ) {
      errors.push(originalHttpError.error.message ? originalHttpError.error.message : originalHttpError.error.codeError);
    } else {
      if (originalHttpError.status === 500) {
        errors.push("Infelizmente a aplicação respondeu de forma inesperada. Por favor, tente novamente. Se o problema persistir contate nosso suporte.");
      }
      if (originalHttpError.status === 404) {
        errors.push("Os recursos dessa página não foram localizados.");
      }
      if (originalHttpError.status === 400) {
        errors.push("Ocorreu um erro processando sua solicitação. Por favor, tente novamente. Se o problema persistir contate nosso suporte.");
      }
    }

    var finally_error = {
      status: originalHttpError.status,
      url: originalHttpError.url,
      messageServer: originalHttpError?.message  ? originalHttpError?.message : originalHttpError?.statusText,
      message: originalHttpError.error?.message ? originalHttpError?.message : originalHttpError?.statusText,
      errors: errors,
    };

    return finally_error;
  }
}
